
import {defineComponent} from "vue";
import {mapGetters} from "vuex";
import randomString from "random-string";

import {store} from "../store";
import * as requestActions from "../store/requestActions";
import RoomClient from "../services/roomClient";
import ApiService from "../services/api.service";
import ActionService from "../services/action.service";
//import Logger from "../services/logger";
import deviceInfo from "../services/deviceInfo";
import * as stateActions from "../store/stateActions";
import getBrowserFingerprint from "get-browser-fingerprint";
import Popper from "vue3-popper";

//const logger = new Logger();
// eslint-disable-next-line @typescript-eslint/no-var-requires
import LoggerAxios from "@/services/logger_service"
const logger = new LoggerAxios(
    "livecart_front"
);

export default defineComponent({
  name: "room",
  components: {
    Popper,
  },
	data() {
		return {
      showGeo: true,
			phone: "+380",
			optLoading: false,
			loadAudioOutput: false,
      loadAudioOutputResult: false,
      loadAudioOutputResultShow: false,
      showProducts: false,
      showProduct: false,
      products: [],
      serverLink: "",
      showProductIndex: 0,
			code: null,
      location: null,
      ip: null,
			roomId: null,
			token: null,
			isActivePhoto: 0,
			activeMask: false,
      maskTypeValue: false,
      maskTypeId: '',
      currentProducer: {
        video: {
          track: null,
          stream: null,
          loaded: false,
        },
        audio: {
          volume: {
            analyser: {},
            source: {},
            context: {},
            level: 0,
          },
          self: false,
          track: null,
          stream: null,
        },
      },

      currentConsumer: {
        video: {
          track: null,
          stream: null,
          loaded: false,
        },
        audio: {
          volume: 0,
          track: null,
          stream: null,
        },
      },
    };
  },

	methods: {
    showError(error) {
      let x;
      switch(error.code) {
        case error.PERMISSION_DENIED:
          x = "User denied the request for Geolocation."
          break;
        case error.POSITION_UNAVAILABLE:
          x = "Location information is unavailable."
          break;
        case error.TIMEOUT:
          x = "The request to get user location timed out."
          break;
        case error.UNKNOWN_ERROR:
          x = "An unknown error occurred."
          break;
      }

      this.location = (x);
      this.roomClient.GEOLocationCheck({x: null, y: null}, this.roomId);

    },
    showPosition(position) {
      if(!this.location) {
        this.roomClient.GEOLocationCheck({x: position.coords.latitude,  y: position.coords.longitude}, this.roomId);
      }
    },

    getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(this.showPosition, this.showError);
      } else {
        this.location = ("Geolocation is not supported by this browser.");
      }
    },
    callAction(action) {
      if(action === 'show_mask') {
        this.sendMask()
      } else {
        ActionService[action]();
      }
    },
    parseJson(string) {
      try {
        string = JSON.parse(string);
      } catch(e) {
        logger.error(`parseJson() failed:${JSON.stringify(e)}`)
        return false;
      }
      return this.$i18n.locale in string ? string[this.$i18n.locale] : (string.en ? string.en : "");
    },
    start() {
			store.dispatch(stateActions.setPhoneFormOpened(false));
			store.dispatch(stateActions.setCodeFormOpened(false));

      let peerId = this.$route.query.peerId;

      if (!peerId) {
        peerId = getBrowserFingerprint();
      }

      this.roomId = this.$route.params.roomId;

      if (!this.roomId) {
        this.roomId = randomString({ length: 8 }).toLowerCase();
      }

      const currentUser = JSON.parse(localStorage.getItem("user"));

      if (currentUser.phone) {
        this.phone = currentUser.phone;
      }

      let displayName =
        this.$route.query.displayName || currentUser?.name || null;

      this.token = this.$route.query.token;

      if (this.token) {
        localStorage.setItem("token", this.token);
      }

      const handler = this.$route.query.handler;

      // const useSimulcast = this.$route.query.simulcast !== "true";
      const useSimulcast = false;

      // const useSharingSimulcast =
      //   this.$route.query.sharingSimulcast !== "true";
      const useSharingSimulcast = false;

      // const forceTcp = this.$route.query.forceTcp === "true";
      const forceTcp = false;

      // const produce = this.$route.query.produce !== "false";
      const produce = true;

      // const consume = this.$route.query.consume !== "false";
      const consume = true;

      // const forceH264 = this.$route.query.forceH264 === "true";
      const forceH264 = !!this.$route.query.forceH264 || false;

      // const forceVP9 = this.$route.query.forceVP9 === "true";
      const forceVP9 = false;

      const svc = this.$route.query.svc;

      // const datachannel = this.$route.query.datachannel !== "false";
      const datachannel = false;

      // const info = this.$route.query.info === "true";
      const info = false;

      const throttleSecret = this.$route.query.throttleSecret;
      const e2eKey = this.$route.query.e2eKey;

      if (info) {
        // @ts-ignore
        window.SHOW_INFO = true;
      }

      if (throttleSecret) {
        // @ts-ignore
        window.NETWORK_THROTTLE_SECRET = throttleSecret;
      }

      let displayNameSet;

      if (displayName) {
        displayNameSet = true;
      } else {
        displayNameSet = false;
        displayName = "Guest";
      }

      // Get current device info.
      const device = deviceInfo();

      store.dispatch(stateActions.setRoomUrl(this.$route.fullPath));

      // store.dispatch(stateActions.setRoomFaceDetection(faceDetection));

      store.dispatch(
        stateActions.setMe({
          peerId,
          displayName,
          displayNameSet,
          device,
        })
      );

      const roomClient = new RoomClient({
        roomId: this.roomId,
        peerId,
        displayName,
        device,
        handlerName: handler,
        useSimulcast,
        useSharingSimulcast,
        forceTcp,
        produce,
        consume,
        forceH264,
        forceVP9,
        svc,
        datachannel,
        e2eKey,
        token: this.token,
      });

      store.dispatch("roomClient", roomClient);

      this.roomClient.join();
      this.loadAudioOutput = true;
      if(!this.isAdmin) {
        setTimeout(() => { this.getLocation(); }, 5000);
      }
		},

    toggleSpeaker() {
      this.loadAudioOutputResultShow = !this.loadAudioOutputResultShow;
    },

    sendMask() {
      ApiService.showMask(this.roomId, this.maskTypeValue, this.maskTypeId).then(
          (response: any) => {
            this.activeMask = false;
          }).catch(_error => {
            logger.error(`sendMask() failed:${JSON.stringify(_error)}`)
            requestActions.notify({
              type: "danger",
              text: this.$t("notifications.error.unknown"),
            });
      })
    },
    getProducts() {
      ApiService.getProducts(this.token).then(
          (response: any) => {
            response.data?.rows.forEach(product => {
              ApiService.getProductButtons(product.id).then(
                  (responseBtn: any) => {
                    if(responseBtn.data[0]) {
                      product.btns = responseBtn.data.filter(btn => btn.user !== 'client');
                    }
                    this.products.push(product);
                  }).catch((_error) => {
                logger.error(`getProductButtons() failed:${JSON.stringify(_error)}`)
                requestActions.notify({
                  type: "danger",
                  text: this.$t("notifications.error.unknown"),
                });
              });
            })
          }
      ).catch((_error) => {
        logger.error(`getProducts() failed:${JSON.stringify(_error)}`)
        requestActions.notify({
          type: "danger",
          text: this.$t("notifications.error.unknown"),
        });
      });
    },
    createOtp() {
      this.optLoading = true;

      ApiService.createOtp(this.roomId).then(
        (response: any) => {
          this.optLoading = false;

          if (response.data?.status === 200) {
            requestActions.notify({
              type: "success",
              text: this.$t("notifications.code.create"),
            });
          }
        },
        () => {
          this.optLoading = false;

          requestActions.notify({
            type: "danger",
            text: this.$t("notifications.code.notCreate"),
          });
        }
      );
    },

    sendPhone() {
      this.optLoading = true;

      ApiService.sendPhone(this.roomId, this.phone).then(
        (response: any) => {
          this.optLoading = false;
          this.phone = "+380";

          store.dispatch(stateActions.setPhoneFormOpened(false));

          if (response.data?.status === 200) {
            requestActions.notify({
              type: "success",
              text: this.$t("notifications.code.send"),
            });
          } else {
            requestActions.notify({
              type: "danger",
              text: response.data?.error.status,
            });
          }
        },
        () => {
          this.optLoading = false;

          requestActions.notify({
            type: "danger",
            text: this.$t("notifications.error.unknown"),
          });
        }
      );
    },

    verifyPhone() {
      return ~this.phone.search(/^\+380(\d){9}$/g);
    },

    sendCode() {
      this.optLoading = true;

      ApiService.sendCode(this.roomId, this.code).then(
        (response: any) => {
          this.optLoading = false;
          this.code = null;

          if (parseInt(response.data?.status) === 200) {
            store.dispatch(stateActions.setCodeFormOpened(false));

            requestActions.notify({
              type: "success",
              text: response.data?.message,
            });
          } else {
            requestActions.notify({
              type: "danger",
              text: this.$t("notifications.code.notVerified"),
            });
          }
        },
        () => {
          this.optLoading = false;

          requestActions.notify({
            type: "danger",
            text: this.$t("notifications.code.notVerified"),
          });
        }
      );
    },

    inputPhone(e: any) {
      const keyCode = e.keyCode ? e.keyCode : e.which;

      if (keyCode === 13 && this.verifyPhone()) {
        this.sendPhone();
      }
    },

    inputOTP(e: any) {
      const keyCode = e.keyCode ? e.keyCode : e.which;

      if ((keyCode < 48 || keyCode > 57) && keyCode !== 13) {
        e.preventDefault();
      }

      if (keyCode === 13) {
        this.sendCode();
      }
    },

    copyUrl() {
      navigator.clipboard.writeText(
        window.location.origin +
          "?roomId=" +
          this.roomId +
          "&locale=" +
          this.$i18n.locale
      );

      requestActions.notify({
        type: "success",
        text: this.$t("notifications.copy"),
      });
    },

    takePhoto() {
      if(this.currentConsumer.video.track && !this.consumer?.video.stream?.remotelyPaused) {
        if (this.currentConsumer.video.track) {
          this.isActivePhoto = true;

          setTimeout(() => {
            this.isActivePhoto = false;
          }, 350);

          const video = this.$refs.consumerVideo;

          const canvas = this.$refs.consumerCanvas;

          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;

          canvas
              .getContext("2d")
              .drawImage(video, 0, 0, canvas.width, canvas.height);

          const photo = canvas.toDataURL();

          ApiService.sendPhoto(photo, this.roomId);
          ApiService.classifier(photo, this.roomId);
        }
      } else {
        requestActions.notify({
          type: "danger",
          text: this.$t("notifications.webcam.disconnected"),
        });
      }
    },

    toggleRecord() {
      this.room?.record ? this.stopRecord() : this.startRecord();
    },

    startRecord() {
      this.roomClient.startRecord(this.orientationClientValue);
    },

    stopRecord() {
      this.roomClient.stopRecord();
    },

    toggleMic() {
      this.producer.audio.stream.paused
        ? this.roomClient.unmuteMic()
        : this.roomClient.muteMic();
    },

    toggleWebcam() {
      this.producer.video.stream.paused
        ? this.roomClient.unmuteWebcam()
        : this.roomClient.muteWebcam();
    },

    volumeMeterCreate(stream) {
      this.currentProducer.audio.volume.context = new AudioContext();
      this.currentProducer.audio.volume.analyser =
        this.currentProducer.audio.volume.context.createAnalyser();
      this.currentProducer.audio.volume.analyser.fftSize = 256;

      this.currentProducer.audio.volume.source =
        this.currentProducer.audio.volume.context.createMediaStreamSource(
          stream
        );
      this.currentProducer.audio.volume.source.connect(
        this.currentProducer.audio.volume.analyser
      );

      this.volumeMeterOnChange();
    },

    volumeMeterOnChange() {
      const buffer =
        this.currentProducer.audio.volume.analyser.frequencyBinCount;

      const dataArr = new Uint8Array(buffer);
      this.currentProducer.audio.volume.analyser.getByteFrequencyData(dataArr);

      this.currentProducer.audio.volume.level = Math.floor(
        (Math.max(...dataArr) / 256) * 360
      );

      if (this.currentProducer.audio.volume.level < 95) {
        this.currentProducer.audio.volume.level = 35;
      }

      requestAnimationFrame(this.volumeMeterOnChange);
    },

    async toggleOutpuAudio(e) {
      // eslint-disable-next-line no-use-before-define
      this.$refs.consumerAudio.setSinkId(e.target.value);
    },

    async closeRoom() {
      if (this.isAdmin) {
        this.roomClient.stopRecord();
      }
      if (this.isAdmin) {
        await this.roomClient.closeAllPeer();
      } else {
        await this.roomClient.disableMic();
        await this.roomClient.disableWebcam();
        await this.roomClient.close();

        this.$router.push({
          name: "close",
          query: { locale: this.$route.query.locale },
        });
      }
    },
  },

  computed: {
    ...mapGetters([
      "me",
      "producers",
      "consumers",
      "roomClient",
      "peers",
      "volume",
      "room",
      "orientation",
      "geo",
			"clientOrientation",
      "phoneFormOpened",
      "product",
      "codeFormOpened",
    ]),

    clientBtn: function () {
      if (Array.isArray(this.$store.state.me.product.buttons)) {
        return this.$store.state.me.product.buttons.filter(btn => btn.user === 'client' || btn.user === 'all')
      } else {
        return [];
      }
    },

    showBlocks() {
      return this.roomClient.show_blocks;
    },

    appMod() {
      return this.roomClient.retail_mod;
    },

    isAdmin() {
      return this.$store.state.me.isAdmin;
    },

    geo() {
      return this.$store.state.me.geo;
    },

    audioOutputDevices() {
      if (this.roomClient._audioOutputDevices)
        return Object.values(this.roomClient._audioOutputDevices);
      else return [];
    },

    user() {
      return JSON.parse(localStorage.getItem("user"));
    },

    producer() {
      const producersArray = Object.values(this.producers);

      const audioProducer: any = producersArray.find(
        (producer: any) => producer.track.kind === "audio"
      );
      const videoProducer: any = producersArray.find(
        (producer: any) => producer.track.kind === "video"
      );

      return {
        video: {
          track: videoProducer?.track || null,
          stream: videoProducer,
        },
        audio: {
          track: audioProducer?.track || null,
          stream: audioProducer,
        },
      };
    },

    orientationClientValue() {
      return this.clientOrientation;
    },

    consumer() {
      const consumersArray = Object.values(this.consumers);

      const audioConsumer: any = consumersArray.find(
        (consumer: any) => consumer.track.kind === "audio"
      );
      const videoConsumer: any = consumersArray.find(
        (consumer: any) => consumer.track.kind === "video"
      );
      console.log("videoConsumer", videoConsumer)
      return {
        video: {
          track: videoConsumer?.track || null,
          stream: videoConsumer,
        },
        audio: {
          track: audioConsumer?.track || null,
          stream: audioConsumer,
        },
      };
    },

    peerName() {
      console.log(this.peers);
      for (const [key, value] of Object.entries(this.peers) as any) {
        return value.displayName;
      }

      return "";
    },
  },

	watch: {

		orientation(orientation) {
			this.roomClient.orientationChange(orientation, this.room?.record);
		},

    phoneFormOpened() {
      // this.$nextTick(() => this.$refs.tel.focus());
      if (this.verifyPhone()) {
        this.sendPhone();
      }
    },


		codeFormOpened() {
			this.$nextTick(() => this.$refs.code.focus());
		},

    producer(info) {
      if (
        info.audio.track &&
        this.currentProducer.audio.stream?.id !== info.audio.stream?.id
      ) {
        const stream = new MediaStream();

        stream.addTrack(info.audio.track);
        this.currentProducer.audio.track = stream;
        this.currentProducer.audio.stream = info.audio.stream;

        this.volumeMeterCreate(stream);

        if (this.currentProducer.audio.self) {
          this.$refs.producerAudio
            .play()
            .catch((error) => logger.error(`producer audioElem.play() failed:${JSON.stringify(error.message)}`));
        }
      }

      if (
        info.video.track &&
        this.currentProducer.video.stream?.id !== info.video.stream?.id
      ) {
        const stream = new MediaStream();

        stream.addTrack(info.video.track);
        this.currentProducer.video.track = stream;
        this.currentProducer.video.stream = info.video.stream;
        this.currentProducer.video.loaded = true;

        // this._startVideoResolution();
      }
    },

    consumer(info) {
      if (info.video.track) {
        setTimeout(() => {
          let mql;
          if (
            typeof screen != "undefined" &&
            typeof screen.orientation != "undefined"
          ) {
            mql = screen.orientation.type.indexOf("portrait") === 0;
          } else {
            mql = window.matchMedia("(orientation: portrait)").matches;
          }
          if (mql) {
            this.roomClient.orientationChange("portrait");
          } else {
            this.roomClient.orientationChange("landscape");
          }
        });
      }

      if (
        info.audio.track &&
        this.currentConsumer.audio.stream?.id !== info.audio.stream?.id
      ) {
        const stream = new MediaStream();

        stream.addTrack(info.audio.track);
        this.currentConsumer.audio.track = stream;
        this.currentConsumer.audio.stream = info.audio.stream;
        let audio = this.$refs.consumerAudio;
        setTimeout(function () {
            audio
            .play()
            .catch((error) => logger.error(`consumerAudio audioElem.play() failed:${JSON.stringify(error.message)}`));
        }, 150);
      }

      if (
        info.video.track &&
        this.currentConsumer.video.stream?.id !== info.video.stream?.id
      ) {
        const stream = new MediaStream();

        stream.addTrack(info.video.track);
        this.currentConsumer.video.track = stream;
        this.currentConsumer.video.stream = info.video.stream;
        this.currentConsumer.video.loaded = true;
        // this._startVideoResolution();
      }

      if (!info.video.track) {
        this.currentConsumer.video.track = this.currentConsumer.video.stream =
          null;
      }
      console.log("this.currentConsumer.video", this.currentConsumer.video)
      console.log("info.video", info.video.track)
    },
  },

	mounted() {
    this.serverLink = ApiService.getServerLink();
		this.start();
    if (this.loadAudioOutput) {
      // eslint-disable-line no-use-before-define
      if (typeof this.$refs.consumerAudio.sinkId !== "undefined") {
        this.loadAudioOutputResult =
          this.roomClient._updateAudioOutputDevices();
      }
    }
    if(this.isAdmin) {
      this.getProducts();
    }
    // setTimeout(() => {
		//   this.roomClient.changeWebcam();
		// }, 10000);

    this.$refs.producerVideo.oncanplay = () => {
      console.log("producerVideo oncanplay");
      // this.setState({ videoCanPlay: true });
      // this.$refs.producerAudio
      //   .play()
      //   .catch((error) => logger.warn("audioElem.play() failed:%o", error));
    };

    this.$refs.producerVideo.onplay = () => {
      console.log("producerVideo onplay");
      // this.setState({ videoElemPaused: false });
    };

    this.$refs.producerVideo.onpause = () => {
      console.log("producerVideo onpause");
      // this.setState({ videoElemPaused: true });
    };

    this.$refs.consumerVideo.oncanplay = () => {
      console.log("consumerVideo oncanplay");
      // this.setState({ videoCanPlay: true });
      // this.$refs.consumerVideo
      //   .play()
      //   .catch((error) => logger.warn("audioElem.play() failed:%o", error));
    };

    this.$refs.consumerVideo.onplay = () => {
      console.log("consumerVideo onplay");
      // this.setState({ videoElemPaused: false });
    };

    this.$refs.consumerVideo.onpause = () => {
      console.log("consumerVideo onpause");
      // this.setState({ videoElemPaused: true });
    };
  },
});
