export default class UrlFactory {
  private link = process.env.VUE_APP_WS_LINK;

  constructor() {
    // if (window.location.hostname === "test.mediasoup.org") {
    //   this.protooPort = 4444;
    // }
  }

  public getProtooUrl({ roomId, peerId, token = false }) {
    let setToken = '';

    if (token) {
      setToken = `&token=${token}`;
    }

    return `${this.link}/?roomId=${roomId}&peerId=${peerId}${setToken}`;
  }
}
