import { store } from "../store/index";
import ApiService from "@/services/api.service";

export default class ActionService {
    constructor() {

    }

    static async closeProduct() {
        store.state.me.product = null
    }

    static async close_popup() {
        store.state.me.product = null
    }
    static async show_mask(roomId: string, maskType: string, maskId: string) {
        await ApiService.showMask(roomId, maskType, maskId)
    }
}