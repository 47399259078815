import * as cookiesManager from "./cookiesManager";
import * as e2e from "./e2e";
import * as mediasoupClient from "mediasoup-client";
import * as requestActions from "../store/requestActions";
import * as stateActions from "../store/stateActions";

import {
  BuiltinHandlerName,
  Consumer,
  DataConsumer,
  DataProducer,
  Producer,
  Transport,
} from "mediasoup-client/lib/types";

import Logger from "./logger";
import UrlFactory from "./urlFactory";
import i18n from "../lang";
import protooClient from "protoo-client";
import { store } from "../store/index";
import router from "@/router";
import axios from "axios";
import { setGeo } from "../store/stateActions";
import ApiService from "@/services/api.service";

const VIDEO_CONSTRAINS = {
  qvga: { width: { ideal: 320 }, height: { ideal: 240 } },
  vga: { width: { ideal: 640 }, height: { ideal: 480 } },
  hd: { width: { ideal: 1280 }, height: { ideal: 720 } },
  fullhd: { width: { ideal: 1920 }, height: { ideal: 1080 } },
};

const PC_PROPRIETARY_CONSTRAINTS = {
  optional: [{ googDscp: true }],
};

const WEBCAM_SIMULCAST_ENCODINGS = [
  // { scaleResolutionDownBy: 4, maxBitrate: 400000 },
  // { scaleResolutionDownBy: 2, maxBitrate: 650000 },
  { scaleResolutionDownBy: 1, maxBitrate: 2000000 },
];

const WEBCAM_KSVC_ENCODINGS = [{ scalabilityMode: "S3T3_KEY" }];

const SCREEN_SHARING_SIMULCAST_ENCODINGS = [
  // { dtx: true, maxBitrate: 800000 },
  { dtx: true, maxBitrate: 2000000 },
];

const SCREEN_SHARING_SVC_ENCODINGS = [{ scalabilityMode: "S3T3", dtx: true }];

import LoggerAxios from "@/services/logger_service";
const logger = new LoggerAxios("livecart_front");

export default class RoomClient {
  private _closed = false;
  private _displayName: string;
  private _roomId: string;
  private _peerId: number;
  private _device: any = {};
  private _forceTcp: boolean;
  private _produce: boolean;
  private _consume: boolean;
  private _useDataChannel: boolean;
  private _forceH264: boolean;
  private _forceVP9: boolean;
  private _externalVideo = null;
  private _e2eKey: any;
  private _externalVideoStream = null;
  private _nextDataChannelTestNumber = 0;
  private _handlerName: string;
  private _useSimulcast: boolean;
  private _token: string;
  private _useSharingSimulcast: boolean;
  private _protooUrl: string;
  private _protooTransport: protooClient.WebSocketTransport = null;
  private _protoo: protooClient.Peer = null;
  private _mediasoupDevice: mediasoupClient.Device = null;
  private _sendTransport: Transport = null;
  private _recvTransport: Transport = null;
  private _micProducer: Producer = null;
  private _webcamProducer: Producer = null;
  private _shareProducer: Producer = null;
  private _chatDataProducer: DataProducer = null;
  private _botDataProducer: DataProducer = null;
  private _consumers = new Map<string, Consumer>();
  private _dataConsumers = new Map<string, DataConsumer>();
  private _webcams = new Map<string, MediaDeviceInfo>();
  private _webcam = {
    device: null,
    resolution: "hd",
  };
  private _audioOutputDevices = {};
  public retail_mod = false;
  public show_blocks = false;

  constructor({
    roomId,
    peerId,
    displayName,
    device,
    handlerName,
    useSimulcast,
    useSharingSimulcast,
    forceTcp,
    produce,
    consume,
    forceH264,
    forceVP9,
    svc,
    datachannel,
    e2eKey,
    token,
  }) {
    logger.debug(
      `constructor() [roomId:"${roomId}", peerId:"${peerId}", displayName:"${displayName}", device:${device.flag}]`
    );

    this._closed = false;
    this._displayName = displayName;
    this._roomId = roomId;
    this._peerId = peerId;
    this._token = token;
    this._device = device;
    this._forceTcp = forceTcp;
    this._produce = produce;
    this._consume = consume;
    this._useDataChannel = datachannel;
    this._forceH264 = Boolean(forceH264);
    this._forceVP9 = Boolean(forceVP9);
    this._e2eKey = e2eKey;
    this._nextDataChannelTestNumber = 0;

    this._handlerName = handlerName;
    this._useSimulcast = useSimulcast;
    this._useSharingSimulcast = useSharingSimulcast;
    this._protooUrl = new UrlFactory().getProtooUrl({ roomId, peerId, token });

    if (svc) {
      WEBCAM_KSVC_ENCODINGS[0].scalabilityMode = `${svc}_KEY`;
      SCREEN_SHARING_SVC_ENCODINGS[0].scalabilityMode = svc;
    }

    if (this._e2eKey && e2e.isSupported()) {
      e2e.setCryptoKey("setCryptoKey", this._e2eKey, true);
    }
  }

  async closeAllPeer() {
    await this._protoo.request("closeClient", {});
  }

  sendProductToClient(id) {
    this._protoo.request("sendProductToClient", { id: id });
  }

  close() {
    if (this._closed || !this._protoo) {
      return;
    }

    this._closed = true;

    logger.debug("close()");

    this._protoo.close();

    if (this._sendTransport) {
      this._sendTransport.close();
    }

    if (this._recvTransport) {
      this._recvTransport.close();
    }

    const closed = stateActions.setRoomState("closed");
    closed.type.filter((t) => store.dispatch(t, closed));
  }

  async join() {
    this._protooTransport = new protooClient.WebSocketTransport(
      this._protooUrl,
      {
        retry: {
          forever: true,
          factor: 1,
        },
      }
    );

    this._protoo = new protooClient.Peer(this._protooTransport);

    const connecting = stateActions.setRoomState("connecting");
    connecting.type.filter((t) => store.dispatch(t, connecting));

    this._protoo.on("open", () => this._joinRoom());

    this._protoo.on("failed", () => {
      // requestActions.notify({
      //   type: "danger",
      //   text: "WebSocket connection failed",
      // });
    });

    this._protoo.on("disconnected", () => {
      // requestActions.notify({
      //   type: "danger",
      //   text: "WebSocket disconnected",
      // });

      if (this._sendTransport) {
        this._sendTransport.close();
        this._sendTransport = null;
      }

      if (this._recvTransport) {
        this._recvTransport.close();
        this._recvTransport = null;
      }

      const closed = stateActions.setRoomState("closed");
      closed.type.filter((t) => store.dispatch(t, closed));
    });

    this._protoo.on("close", async () => {
      if (this._closed) return;
      await this.disableMic();
      await this.disableWebcam();
      await this.close();
    });

    // eslint-disable-next-line no-unused-vars
    this._protoo.on("request", async (request, accept, reject) => {
      logger.debug(
        `proto "request" event [method:${request.method}, data:${JSON.stringify(
          request.data
        )}]`
      );

      switch (request.method) {
        case "newConsumer": {
          if (!this._consume) {
            reject(403, "I do not want to consume");

            break;
          }

          const {
            peerId,
            producerId,
            id,
            kind,
            rtpParameters,
            type,
            appData,
            producerPaused,
          } = request.data;

          try {
            const consumer = await this._recvTransport.consume({
              id,
              producerId,
              kind,
              rtpParameters,
              appData: { ...appData, peerId }, // Trick.
            });

            if (this._e2eKey && e2e.isSupported()) {
              e2e.setupReceiverTransform(consumer.rtpReceiver);
            }

            this._consumers.set(consumer.id, consumer);

            consumer.on("transportclose", () => {
              this._consumers.delete(consumer.id);
            });

            const { spatialLayers, temporalLayers } =
              mediasoupClient.parseScalabilityMode(
                consumer.rtpParameters.encodings[0].scalabilityMode
              );

            const addConsumer = stateActions.addConsumer(
              {
                id: consumer.id,
                type: type,
                locallyPaused: false,
                remotelyPaused: producerPaused,
                rtpParameters: consumer.rtpParameters,
                spatialLayers: spatialLayers,
                temporalLayers: temporalLayers,
                preferredSpatialLayer: spatialLayers - 1,
                preferredTemporalLayer: temporalLayers - 1,
                priority: 1,
                codec: consumer.rtpParameters.codecs[0].mimeType.split("/")[1],
                track: consumer.track,
              },
              peerId
            );
            if (!store.state.me.isAdmin) {
              console.log('Consumer', consumer);
              console.log('Consumer track', consumer.track);
            }
            addConsumer.type.filter((t) => store.dispatch(t, addConsumer));

            accept();

            if (consumer.kind === "video" && store.state.me.audioOnly) {
              this._pauseConsumer(consumer);
            }
          } catch (error) {
            logger.error(
              `"newConsumer" request failed:${JSON.stringify(error.message)}`
            );
            requestActions.notify({
              type: "danger",
              text: `Error creating a Consumer: ${error}`,
            });
            if (!store.state.me.isAdmin) {
              /*await this.muteMic();
              await this.muteWebcam();
              await this.close();
              await this.join();*/
            }
            //throw error;
          }

          break;
        }

        case "inputPhone": {
          store.dispatch(stateActions.setPhoneFormOpened(true));

          accept();

          break;
        }

        case "inputCode": {
          store.dispatch(stateActions.setCodeFormOpened(true));

          accept();

          break;
        }

        case "orientationChange": {
          const { orientation } = request.data;

          store.dispatch(stateActions.setClientOrientation(orientation));

          accept();

          break;
        }
      }
    });

    this._protoo.on("notification", (notification) => {
      logger.debug(
        `proto "notification" event [method:${
          notification.method
        }, data:${JSON.stringify(notification.data)}]`
      );

      switch (notification.method) {
        case "codeSended": {
          const { code, phone } = notification.data;

          requestActions.notify({
            text: `Code ${code} sended to ${phone}`,
          });

          break;
        }

        case "codeVerified": {
          const { code, phone } = notification.data;

          requestActions.notify({
            text: `Code ${code} verivied by ${phone}`,
          });

          break;
        }

        case "noPlaces": {
          requestActions.notify({
            text: `No places`,
            type: "danger",
          });

          break;
        }

        case "stopRecordOrientationChange": {
          requestActions.notify({
            text: i18n.global.t("notifications.record.orientationChange"),
            type: "danger",
          });

          break;
        }

        case "unavailableService": {
          router.push("/unavailable");

          break;
        }

        case "Geo": {
          const position = notification.data;
          store.dispatch(
            stateActions.setGeo(notification.data.position.address)
          );

          break;
        }
        case "customNotify": {
          requestActions.notify({
            text: notification.data.message,
            type: "success",
          });

          break;
        }

        case "showMask": {
          const maskType = notification.data.maskType;

          store.dispatch(stateActions.setMaskType(maskType));

          break;
        }

        case "startRecord": {
          store.dispatch(stateActions.setRecord(true));

          break;
        }

        case "stopRecord": {
          store.dispatch(stateActions.setRecord(false));

          break;
        }

        case "errorRecord": {
          const { message } = notification.data;

          let t = "";

          switch (message) {
            case "video":
              t = i18n.global.t("notifications.record.video");
              break;

            case "audio":
              t = i18n.global.t("notifications.record.audio");
              break;

            case "adminAudio":
              t = i18n.global.t("notifications.record.adminAudio");
              break;
          }

          store.dispatch(stateActions.setRecord(false, t));

          break;
        }

        case "muteMic": {
          this._consumers.forEach((consumer) => {
            if (consumer.kind === "audio") {
              store.dispatch(
                stateActions.setConsumerPaused(consumer.id, "remote")
              );
            }
          });

          break;
        }

        case "unmuteMic": {
          this._consumers.forEach((consumer) => {
            if (consumer.kind === "audio") {
              store.dispatch(
                stateActions.setConsumerResumed(consumer.id, "remote")
              );
            }
          });

          break;
        }

        case "muteWebcam": {
          this._consumers.forEach((consumer) => {
            if (consumer.kind === "video") {
              store.dispatch(
                stateActions.setConsumerPaused(consumer.id, "remote")
              );
            }
          });

          break;
        }

        case "unmuteWebcam": {
          this._consumers.forEach((consumer) => {
            if (consumer.kind === "video") {
              store.dispatch(
                stateActions.setConsumerResumed(consumer.id, "remote")
              );
            }
          });

          break;
        }

        case "producerScore": {
          const { producerId, score } = notification.data;

          store.dispatch(stateActions.setProducerScore(producerId, score));

          break;
        }

        case "closeRoomClient": {
          this.disableMic();
          this.disableWebcam();
          this.close();
          router.push({ name: "close", query: { locale: i18n.locale } });
          break;
        }

        case "getProductFromAdmin": {
          const id = notification.data;

          ApiService.getProduct(id.id)
            .then((response: any) => {
              ApiService.getProductButtons(id.id)
                .then((responseBtn: any) => {
                  response.data.buttons = responseBtn.data;
                  store.state.me.product = response.data;
                  store.state.me.maskType = null;
                })
                .catch((_error) => {
                  requestActions.notify({
                    type: "danger",
                    text: i18n.global.t("notifications.error.unknown"),
                  });
                });
            })
            .catch((_error) => {
              requestActions.notify({
                type: "danger",
                text: i18n.global.t("notifications.error.unknown"),
              });
            });

          break;
        }

        case "newPeer": {
          const peer = notification.data;

          store.dispatch(
            stateActions.addPeer({ ...peer, consumers: [], dataConsumers: [] })
          );

          requestActions.notify({
            text: i18n.global.t("notifications.peer.new", {
              peerName: peer.displayName,
            }),
          });
          setTimeout(() => {
            this.muteWebcam();
            this.muteMic();
            this.unmuteWebcam();
            this.unmuteMic();
          }, 500);
          // TODO: remove and search solution
          if (!peer.isAdmin) {
            /*store.dispatch(stateActions.setRefreshState(true));

            setTimeout(() => {
              store.dispatch("componentKey");
            }, 500);*/

            return;
          }

          break;
        }

        case "peerDisconnected": {
          const { displayName } = notification.data;

          requestActions.notify({
            text: "peerDisconnected",
            type: "danger",
          });

          setTimeout(() => {
            store.dispatch(stateActions.setRecord(false));
          });

          break;
        }

        case "peerClosed": {
          const { peerId } = notification.data;
          const removePeer = stateActions.removePeer(peerId);

          // store.dispatch(stateActions.setRecord(false));

          removePeer.type.filter((t) => store.dispatch(t, removePeer));

          break;
        }

        case "peerDisplayNameChanged": {
          const { peerId, displayName, oldDisplayName } = notification.data;

          store.dispatch(stateActions.setPeerDisplayName(displayName, peerId));

          requestActions.notify({
            text: `${oldDisplayName} is now ${displayName}`,
          });

          break;
        }

        case "downlinkBwe": {
          logger.debug(
            `'downlinkBwe' event:${JSON.stringify(notification.data)}`
          );

          break;
        }

        case "consumerClosed": {
          const { consumerId } = notification.data;
          const consumer = this._consumers.get(consumerId);

          if (!consumer) break;

          consumer.close();
          this._consumers.delete(consumerId);

          const { peerId } = consumer.appData;

          const removeConsumer = stateActions.removeConsumer(
            consumerId,
            peerId
          );

          removeConsumer.type.filter((t) => store.dispatch(t, removeConsumer));

          break;
        }

        case "consumerPaused": {
          const { consumerId } = notification.data;
          const consumer = this._consumers.get(consumerId);

          if (!consumer) break;

          consumer.pause();

          store.dispatch(stateActions.setConsumerPaused(consumerId, "remote"));

          break;
        }

        case "consumerResumed": {
          const { consumerId } = notification.data;
          const consumer = this._consumers.get(consumerId);

          if (!consumer) break;

          consumer.resume();

          store.dispatch(stateActions.setConsumerResumed(consumerId, "remote"));

          break;
        }

        case "consumerLayersChanged": {
          const { consumerId, spatialLayer, temporalLayer } = notification.data;
          const consumer = this._consumers.get(consumerId);

          if (!consumer) break;

          store.dispatch(
            stateActions.setConsumerCurrentLayers(
              consumerId,
              spatialLayer,
              temporalLayer
            )
          );

          break;
        }

        case "consumerScore": {
          const { consumerId, score } = notification.data;

          store.dispatch(stateActions.setConsumerScore(consumerId, score));

          break;
        }

        case "activeSpeaker": {
          store.dispatch(stateActions.setRoomActiveSpeaker(notification.data));

          break;
        }

        default: {
          logger.error(
            `unknown protoo notification.method "${notification.method}"`
          );
        }
      }
    });
  }

  async enableMic() {
    logger.debug("enableMic()");

    if (this._micProducer) return;
    if (this._protooTransport.closed) return;

    if (!this._mediasoupDevice.canProduce("audio")) {
      logger.warn("enableMic() cannot produce audio");

      return;
    }

    let track;

    try {
      if (!this._externalVideo) {
        logger.debug("enableMic() calling getUserMedia()");

        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });

        track = stream.getAudioTracks()[0];
      } else {
        const stream = await this._getExternalVideoStream();

        track = stream.getAudioTracks()[0].clone();
      }

      this._micProducer = await this._sendTransport.produce({
        track,
        codecOptions: {
          opusStereo: true,
          opusDtx: false, // отключать поток аудио, если не разговариваешь
        },
      });

      if (this._e2eKey && e2e.isSupported()) {
        e2e.setupSenderTransform(this._micProducer.rtpSender);
      }

      store.dispatch(
        stateActions.addProducer({
          id: this._micProducer.id,
          paused: this._micProducer ? this._micProducer.paused : true,
          track: this._micProducer.track,
          rtpParameters: this._micProducer.rtpParameters,
          codec:
            this._micProducer.rtpParameters.codecs[0].mimeType.split("/")[1],
        })
      );

      this._micProducer.on("transportclose", () => {
        this._micProducer = null;
      });

      this._micProducer.on("trackended", () => {
        requestActions.notify({
          type: "danger",
          text: i18n.global.t("notifications.microphone.disconnected"),
        });

        this.disableMic().catch(() => {});
      });

      // if (this._token) {
      //   this.muteMic();
      // }

      if (store.state.me.micMuted) {
        this.muteMic();
      }
    } catch (error) {
      logger.error(`enableMic() failed:${JSON.stringify(error.message)}`);

      requestActions.notify({
        type: "danger",
        text: i18n.global.t("notifications.microphone.error.on", error),
      });

      if (track) {
        track.stop();
      }
    }
  }

  // TODO: remove
  async disableMic() {
    logger.debug("disableMic()");

    if (!this._micProducer) return;
    if (!this._protoo) return;
    if (this._protooTransport.closed) return;

    this._micProducer.close();

    store.dispatch(stateActions.removeProducer(this._micProducer.id));

    try {
      await this._protoo.request("closeProducer", {
        producerId: this._micProducer.id,
      });
    } catch (error) {
      logger.error(`disableMic() failed:${JSON.stringify(error.message)}`);
      requestActions.notify({
        type: "danger",
        text: i18n.global.t("notifications.microphone.error.close", error),
      });
    }

    this._micProducer = null;
  }

  orientationChange(orientation, record = false) {
    if (this._protoo) {
      this._protoo.request("orientationChange", {
        peerId: this._peerId,
        record: record,
        orientation,
      });
    }
  }

  async GEOLocationCheck(location, roomId) {
    return axios.post(process.env.VUE_APP_SERVER_LINK + `/room/${roomId}/geo`, {
      ClientLocation: location,
    });
  }

  async sendCallbackEvent(event, roomId) {
    return axios.get(process.env.VUE_APP_SERVER_LINK + `/room/callback_event/${roomId}/${event}`);
  }

  async getAppMod() {
    const result = await axios.get(process.env.VUE_APP_SERVER_LINK + `/settings/app_mod`);
    if (result) {
      if (result?.data?.value === '0')
        return false;

      if (result?.data?.value === '1')
        return true;
    }

    return false;
  }

  async muteMic() {
    logger.debug("muteMic()");

    if (!this._micProducer) return;
    if (this._protooTransport.closed) return;

    this._micProducer.pause();

    try {
      await this._protoo.request("muteMic", {
        producerId: this._micProducer.id,
      });

      store.dispatch(stateActions.setMicState(true));
      store.dispatch(stateActions.setProducerPaused(this._micProducer.id));
    } catch (error) {
      logger.error(`muteMic() failed:${JSON.stringify(error.message)}`);

      requestActions.notify({
        type: "danger",
        text: i18n.global.t("notifications.microphone.error.pausing", error),
      });
    }
  }

  async _updateAudioOutputDevices() {
    logger.debug("_updateAudioOutputDevices()");

    // Reset the list.
    this._audioOutputDevices = {};

    try {
      logger.debug("_updateAudioOutputDevices() calling enumerateDevices()");

      const devices = await navigator.mediaDevices.enumerateDevices();

      for (const device of devices) {
        if (device.kind !== "audiooutput") continue;

        this._audioOutputDevices[device.deviceId] = device;
      }
    } catch (error) {
      logger.error(
        `_updateAudioOutputDevices() [error:"${JSON.stringify(error.message)}"]`
      );
    }
  }

  async unmuteMic() {
    logger.debug("unmuteMic()");

    if (!this._micProducer) return;
    if (this._protooTransport.closed) return;

    this._micProducer.resume();

    try {
      await this._protoo.request("unmuteMic", {
        producerId: this._micProducer.id,
      });

      store.dispatch(stateActions.setMicState(false));
      store.dispatch(stateActions.setProducerResumed(this._micProducer.id));
    } catch (error) {
      logger.error(`unmuteMic() failed:${JSON.stringify(error.message)}`);

      requestActions.notify({
        type: "danger",
        text: i18n.global.t("notifications.microphone.error.resuming", error),
      });
    }
  }

  async enableWebcam() {
    logger.debug("enableWebcam()");

    if (this._protooTransport.closed) return;

    if (this._webcamProducer) return;
    else if (this._shareProducer) await this.disableShare();

    if (!this._mediasoupDevice.canProduce("video")) {
      logger.warn("enableWebcam() cannot produce video");

      return;
    }

    let track;
    let device;

    store.dispatch(stateActions.setWebcamInProgress(true));

    try {
      if (!this._externalVideo) {
        await this._updateWebcams();
        device = this._webcam.device;

        const { resolution } = this._webcam;

        if (!device) throw new Error("no webcam devices");

        logger.debug("enableWebcam() calling getUserMedia()");

        const stream = await navigator.mediaDevices.getUserMedia({
          video: {
            deviceId: { ideal: device.deviceId },
            ...VIDEO_CONSTRAINS[resolution],
          },
        });

        track = stream.getVideoTracks()[0];
      } else {
        device = { label: "external video" };

        const stream = await this._getExternalVideoStream();

        track = stream.getVideoTracks()[0].clone();
      }

      let encodings;
      let codec;
      const codecOptions = {
        videoGoogleStartBitrate: 4000,
        videoGoogleMaxBitrate: 10000,
      };

      if (this._forceH264) {
        codec = this._mediasoupDevice.rtpCapabilities.codecs.find(
          (c) => c.mimeType.toLowerCase() === "video/h264"
        );

        if (!codec) {
          throw new Error("desired H264 codec+configuration is not supported");
        }
      } else if (this._forceVP9) {
        codec = this._mediasoupDevice.rtpCapabilities.codecs.find(
          (c) => c.mimeType.toLowerCase() === "video/vp9"
        );

        if (!codec) {
          throw new Error("desired VP9 codec+configuration is not supported");
        }
      }

      if (this._useSimulcast) {
        const firstVideoCodec =
          this._mediasoupDevice.rtpCapabilities.codecs.find(
            (c) => c.kind === "video"
          );

        if (
          (this._forceVP9 && codec) ||
          firstVideoCodec.mimeType.toLowerCase() === "video/vp9"
        ) {
          encodings = WEBCAM_KSVC_ENCODINGS;
        } else {
          encodings = WEBCAM_SIMULCAST_ENCODINGS;
        }
      }

      this._webcamProducer = await this._sendTransport.produce({
        track,
        encodings,
        codecOptions,
        codec,
      });

      if (this._e2eKey && e2e.isSupported()) {
        e2e.setupSenderTransform(this._webcamProducer.rtpSender);
      }

      store.dispatch(
        stateActions.addProducer({
          id: this._webcamProducer.id,
          deviceLabel: device.label,
          type: this._getWebcamType(device),
          paused: this._webcamProducer.paused,
          track: this._webcamProducer.track,
          rtpParameters: this._webcamProducer.rtpParameters,
          codec:
            this._webcamProducer.rtpParameters.codecs[0].mimeType.split("/")[1],
        })
      );

      this._webcamProducer.on("transportclose", () => {
        this._webcamProducer = null;
      });

      this._webcamProducer.on("trackended", () => {
        requestActions.notify({
          type: "danger",
          text: i18n.global.t("notifications.webcam.disconnected"),
        });

        this.disableWebcam().catch(() => {});
      });

      console.log(
        "store.state.room.isRefresh",
        store.state.room.isRefresh,
        store.state.me.webcamMuted
      );

      if (
        (store.state.room.isRefresh &&
          store.state.me.webcamMuted &&
          this._token) ||
        (!store.state.room.isRefresh &&
          !store.state.me.webcamMuted &&
          this._token)
      ) {
        this.muteWebcam();
      }
    } catch (error) {
      logger.error(`enableWebcam() failed:${JSON.stringify(error.message)}`);

      requestActions.notify({
        type: "danger",
        text: i18n.global.t("notifications.webcam.error.on", error),
      });

      if (track) {
        track.stop();
      }
    }

    store.dispatch(stateActions.setWebcamInProgress(false));
  }

  async disableWebcam() {
    logger.debug("disableWebcam()");

    if (!this._webcamProducer) return;
    if (!this._protoo) return;
    if (this._protooTransport.closed) return;

    this._webcamProducer.close();

    store.dispatch(stateActions.removeProducer(this._webcamProducer.id));

    try {
      await this._protoo.request("muteWebcam", {
        producerId: this._webcamProducer.id,
      });
      await this._protoo.request("closeProducer", {
        producerId: this._webcamProducer.id,
      });
    } catch (error) {
      logger.error(`disableWebcam() failed:${JSON.stringify(error.message)}`);

      requestActions.notify({
        type: "danger",
        text: i18n.global.t("notifications.webcam.error.close", error),
      });
    }

    this._webcamProducer = null;
  }

  async muteWebcam() {
    logger.debug("disableWebcam()");

    if (!this._webcamProducer) return;
    if (this._protooTransport.closed) return;

    this._webcamProducer.pause();

    store.dispatch(stateActions.setWebcamState(true));
    store.dispatch(stateActions.setProducerPaused(this._webcamProducer.id));

    try {
      await this._protoo.request("muteWebcam", {
        producerId: this._webcamProducer.id,
      });
    } catch (error) {
      logger.error(`muteWebcam() failed:${JSON.stringify(error.message)}`);

      requestActions.notify({
        type: "danger",
        text: i18n.global.t("notifications.webcam.error.close", error),
      });
    }
  }

  async unmuteWebcam() {
    logger.debug("disableWebcam()");

    if (!this._webcamProducer) return;
    if (this._protooTransport.closed) return;

    this._webcamProducer.resume();

    store.dispatch(stateActions.setWebcamState(false));
    store.dispatch(stateActions.setProducerResumed(this._webcamProducer.id));

    try {
      await this._protoo.request("unmuteWebcam", {
        producerId: this._webcamProducer.id,
      });
    } catch (error) {
      logger.error(`unmuteWebcam() failed:${JSON.stringify(error.message)}`);

      requestActions.notify({
        type: "danger",
        text: i18n.global.t("notifications.webcam.error.close", error),
      });
    }
  }

  async changeWebcam() {
    logger.debug("changeWebcam()");

    store.dispatch(stateActions.setWebcamInProgress(true));

    try {
      await this._updateWebcams();

      const array = Array.from(this._webcams.keys());
      const len = array.length;
      const deviceId = this._webcam.device
        ? this._webcam.device.deviceId
        : undefined;
      let idx = array.indexOf(deviceId);

      if (idx < len - 1) idx++;
      else idx = 0;

      this._webcam.device = this._webcams.get(array[idx]);

      logger.debug(
        `enableMic() new selected webcam [device:${JSON.stringify(
          this._webcam.device
        )}]`
      );

      this._webcam.resolution = "hd";

      if (!this._webcam.device) throw new Error("no webcam devices");

      this._webcamProducer.track.stop();

      logger.debug("changeWebcam() calling getUserMedia()");

      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          deviceId: { exact: this._webcam.device.deviceId },
          ...VIDEO_CONSTRAINS[this._webcam.resolution],
        },
      });

      const track = stream.getVideoTracks()[0];

      await this._webcamProducer.replaceTrack({ track });

      store.dispatch(
        stateActions.setProducerTrack(this._webcamProducer.id, track)
      );
    } catch (error) {
      logger.error(`changeWebcam() failed:${JSON.stringify(error.message)}`);

      requestActions.notify({
        type: "danger",
        text: i18n.global.t("notifications.webcam.error.change", error),
      });
    }

    store.dispatch(stateActions.setWebcamInProgress(false));
  }

  async changeWebcamResolution() {
    logger.debug("changeWebcamResolution()");

    store.dispatch(stateActions.setWebcamInProgress(true));

    try {
      switch (this._webcam.resolution) {
        case "qvga":
          this._webcam.resolution = "vga";
          break;
        case "vga":
          this._webcam.resolution = "hd";
          break;
        case "hd":
          this._webcam.resolution = "qvga";
          break;
        default:
          this._webcam.resolution = "hd";
      }

      logger.debug("changeWebcamResolution() calling getUserMedia()");

      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          deviceId: { exact: this._webcam.device.deviceId },
          ...VIDEO_CONSTRAINS[this._webcam.resolution],
        },
      });

      const track = stream.getVideoTracks()[0];

      await this._webcamProducer.replaceTrack({ track });

      store.dispatch(
        stateActions.setProducerTrack(this._webcamProducer.id, track)
      );
    } catch (error) {
      logger.error(
        `changeWebcamResolution() failed:${JSON.stringify(error.message)}`
      );

      requestActions.notify({
        type: "danger",
        text: i18n.global.t("notifications.webcam.error.resolution", error),
      });
    }

    store.dispatch(stateActions.setWebcamInProgress(false));
  }

  async enableShare() {
    logger.debug("enableShare()");

    if (this._shareProducer) return;
    else if (this._webcamProducer) await this.disableWebcam();

    if (!this._mediasoupDevice.canProduce("video")) {
      logger.warn("enableShare() cannot produce video");

      return;
    }

    let track;

    store.dispatch(stateActions.setShareInProgress(true));

    try {
      logger.debug("enableShare() calling getUserMedia()");

      // @ts-ignore
      const stream = await navigator.mediaDevices.getDisplayMedia({
        audio: false,
        video: {
          displaySurface: "monitor",
          logicalSurface: true,
          cursor: true,
          width: { max: 1920 },
          height: { max: 1080 },
          frameRate: { max: 30 },
        },
      });

      if (!stream) {
        store.dispatch(stateActions.setShareInProgress(true));

        return;
      }

      track = stream.getVideoTracks()[0];

      let encodings;
      let codec;
      const codecOptions = {
        videoGoogleStartBitrate: 8000,
      };

      if (this._forceH264) {
        codec = this._mediasoupDevice.rtpCapabilities.codecs.find(
          (c) => c.mimeType.toLowerCase() === "video/h264"
        );

        if (!codec) {
          throw new Error("desired H264 codec+configuration is not supported");
        }
      } else if (this._forceVP9) {
        codec = this._mediasoupDevice.rtpCapabilities.codecs.find(
          (c) => c.mimeType.toLowerCase() === "video/vp9"
        );

        if (!codec) {
          throw new Error("desired VP9 codec+configuration is not supported");
        }
      }

      if (this._useSharingSimulcast) {
        const firstVideoCodec =
          this._mediasoupDevice.rtpCapabilities.codecs.find(
            (c) => c.kind === "video"
          );

        if (
          (this._forceVP9 && codec) ||
          firstVideoCodec.mimeType.toLowerCase() === "video/vp9"
        ) {
          encodings = SCREEN_SHARING_SVC_ENCODINGS;
        } else {
          encodings = SCREEN_SHARING_SIMULCAST_ENCODINGS.map((encoding) => ({
            ...encoding,
            dtx: true,
          }));
        }
      }

      this._shareProducer = await this._sendTransport.produce({
        track,
        encodings,
        codecOptions,
        codec,
        appData: {
          share: true,
        },
      });

      if (this._e2eKey && e2e.isSupported()) {
        e2e.setupSenderTransform(this._shareProducer.rtpSender);
      }

      store.dispatch(
        stateActions.addProducer({
          id: this._shareProducer.id,
          type: "share",
          paused: this._shareProducer.paused,
          track: this._shareProducer.track,
          rtpParameters: this._shareProducer.rtpParameters,
          codec:
            this._shareProducer.rtpParameters.codecs[0].mimeType.split("/")[1],
        })
      );

      this._shareProducer.on("transportclose", () => {
        this._shareProducer = null;
      });

      this._shareProducer.on("trackended", () => {
        requestActions.notify({
          type: "danger",
          text: i18n.global.t("notifications.share.disconnected"),
        });

        this.disableShare().catch(() => {});
      });
    } catch (error) {
      logger.error(`enableShare() failed:${JSON.stringify(error.message)}`);

      if (error.name !== "NotAllowedError") {
        requestActions.notify({
          type: "danger",
          text: i18n.global.t("notifications.webcam.error.sharing", error),
        });
      }

      if (track) {
        track.stop();
      }
    }

    store.dispatch(stateActions.setShareInProgress(false));
  }

  async disableShare() {
    logger.debug("disableShare()");

    if (!this._shareProducer) return;

    this._shareProducer.close();

    store.dispatch(stateActions.removeProducer(this._shareProducer.id));

    try {
      await this._protoo.request("closeProducer", {
        producerId: this._shareProducer.id,
      });
    } catch (error) {
      logger.error(`disableShare() failed:${JSON.stringify(error.message)}`);

      requestActions.notify({
        type: "danger",
        text: i18n.global.t("notifications.share.error.close", error),
      });
    }

    this._shareProducer = null;
  }

  async enableAudioOnly() {
    logger.debug("enableAudioOnly()");

    store.dispatch(stateActions.setAudioOnlyInProgress(true));

    this.disableWebcam();

    for (const consumer of this._consumers.values()) {
      if (consumer.kind !== "video") continue;

      this._pauseConsumer(consumer);
    }

    store.dispatch(stateActions.setAudioOnlyState(true));

    store.dispatch(stateActions.setAudioOnlyInProgress(false));
  }

  async disableAudioOnly() {
    logger.debug("disableAudioOnly()");

    store.dispatch(stateActions.setAudioOnlyInProgress(true));

    if (
      !this._webcamProducer &&
      this._produce &&
      (cookiesManager.getDevices() || {}).webcamEnabled
    ) {
      this.enableWebcam();
    }

    for (const consumer of this._consumers.values()) {
      if (consumer.kind !== "video") continue;

      this._resumeConsumer(consumer);
    }

    store.dispatch(stateActions.setAudioOnlyState(false));

    store.dispatch(stateActions.setAudioOnlyInProgress(false));
  }

  async startRecord(orientation = false) {
    logger.debug("startRecord()");

    // store.dispatch(stateActions.setRecordInProgress(true));

    await this._protoo.request("startRecord", {
      transportId: this._sendTransport.id,
      orientation: orientation,
    });

    // store.dispatch(stateActions.setRecordInProgress(false));
  }

  async stopRecord() {
    logger.debug("stopRecord()");

    // store.dispatch(stateActions.setRecordInProgress(true));

    await this._protoo.request("stopRecord", {
      transportId: this._sendTransport.id,
    });

    // store.dispatch(stateActions.setRecordInProgress(false));
  }

  async restartIce() {
    logger.debug("restartIce()");

    store.dispatch(stateActions.setRestartIceInProgress(true));

    try {
      if (this._sendTransport) {
        const iceParameters = await this._protoo.request("restartIce", {
          transportId: this._sendTransport.id,
        });

        await this._sendTransport.restartIce({ iceParameters });
      }

      if (this._recvTransport) {
        const iceParameters = await this._protoo.request("restartIce", {
          transportId: this._recvTransport.id,
        });

        await this._recvTransport.restartIce({ iceParameters });
      }

      requestActions.notify({
        text: "ICE restarted",
      });
    } catch (error) {
      logger.error(`restartIce() failed:${JSON.stringify(error.message)}`);

      requestActions.notify({
        type: "danger",
        text: i18n.global.t("notifications.ice.error.restart", error),
      });
    }

    store.dispatch(stateActions.setRestartIceInProgress(false));
  }

  async setMaxSendingSpatialLayer(spatialLayer) {
    logger.debug(`setMaxSendingSpatialLayer() [spatialLayer:${spatialLayer}]`);

    try {
      if (this._webcamProducer)
        await this._webcamProducer.setMaxSpatialLayer(spatialLayer);
      else if (this._shareProducer)
        await this._shareProducer.setMaxSpatialLayer(spatialLayer);
    } catch (error) {
      logger.error(
        `setMaxSendingSpatialLayer() failed:${JSON.stringify(error.message)}`
      );

      requestActions.notify({
        type: "danger",
        text: `Error setting max sending video spatial layer: ${error}`,
      });
    }
  }

  async setConsumerPreferredLayers(consumerId, spatialLayer, temporalLayer) {
    logger.debug(
      `setConsumerPreferredLayers() [consumerId:${consumerId}, spatialLayer:${spatialLayer}, temporalLayer:${temporalLayer}]`
    );

    try {
      await this._protoo.request("setConsumerPreferredLayers", {
        consumerId,
        spatialLayer,
        temporalLayer,
      });

      store.dispatch(
        stateActions.setConsumerPreferredLayers(
          consumerId,
          spatialLayer,
          temporalLayer
        )
      );
    } catch (error) {
      logger.error(
        `setConsumerPreferredLayers() failed:${JSON.stringify(error.message)}`
      );

      requestActions.notify({
        type: "danger",
        text: `Error setting Consumer preferred layers: ${error}`,
      });
    }
  }

  async setConsumerPriority(consumerId, priority) {
    logger.debug(
      `setConsumerPriority() [consumerId:${consumerId}, priority:${priority}]`
    );

    try {
      await this._protoo.request("setConsumerPriority", {
        consumerId,
        priority,
      });

      store.dispatch(stateActions.setConsumerPriority(consumerId, priority));
    } catch (error) {
      logger.error(
        `setConsumerPriority() failed:${JSON.stringify(error.message)}`
      );

      requestActions.notify({
        type: "danger",
        text: `Error setting Consumer priority: ${error}`,
      });
    }
  }

  async requestConsumerKeyFrame(consumerId) {
    logger.debug(`requestConsumerKeyFrame() [consumerId:${consumerId}]`);

    try {
      await this._protoo.request("requestConsumerKeyFrame", { consumerId });

      requestActions.notify({
        text: "Keyframe requested for video consumer",
      });
    } catch (error) {
      logger.error(
        `requestConsumerKeyFrame() failed:${JSON.stringify(error.message)}`
      );

      requestActions.notify({
        type: "danger",
        text: `Error requesting key frame for Consumer: ${error}`,
      });
    }
  }

  async enableChatDataProducer() {
    logger.debug("enableChatDataProducer()");

    if (!this._useDataChannel) {
      return;
    }

    try {
      this._chatDataProducer = await this._sendTransport.produceData({
        ordered: false,
        maxRetransmits: 1,
        label: "chat",
        appData: { info: "my-chat-DataProducer" },
      });

      store.dispatch(
        stateActions.addDataProducer({
          id: this._chatDataProducer.id,
          sctpStreamParameters: this._chatDataProducer.sctpStreamParameters,
          label: this._chatDataProducer.label,
          protocol: this._chatDataProducer.protocol,
        })
      );

      this._chatDataProducer.on("transportclose", () => {
        this._chatDataProducer = null;
      });

      this._chatDataProducer.on("open", () => {
        logger.debug('chat DataProducer "open" event');
      });

      this._chatDataProducer.on("close", () => {
        logger.warn('chat DataProducer "close" event');

        this._chatDataProducer = null;

        requestActions.notify({
          type: "danger",
          text: "Chat DataProducer closed",
        });
      });

      this._chatDataProducer.on("error", (error) => {
        logger.error(
          `chat DataProducer "error" event:${JSON.stringify(error.message)}`
        );

        requestActions.notify({
          type: "danger",
          text: `Chat DataProducer error: ${error}`,
        });
      });

      this._chatDataProducer.on("bufferedamountlow", () => {
        logger.debug('chat DataProducer "bufferedamountlow" event');
      });
    } catch (error) {
      logger.error(
        `enableChatDataProducer() failed:${JSON.stringify(error.message)}`
      );

      requestActions.notify({
        type: "danger",
        text: `Error enabling chat DataProducer: ${error}`,
      });

      throw error;
    }
  }

  async enableBotDataProducer() {
    logger.debug("enableBotDataProducer()");

    if (!this._useDataChannel) return;

    try {
      this._botDataProducer = await this._sendTransport.produceData({
        ordered: false,
        maxPacketLifeTime: 2000,
        label: "bot",
        appData: { info: "my-bot-DataProducer" },
      });

      store.dispatch(
        stateActions.addDataProducer({
          id: this._botDataProducer.id,
          sctpStreamParameters: this._botDataProducer.sctpStreamParameters,
          label: this._botDataProducer.label,
          protocol: this._botDataProducer.protocol,
        })
      );

      this._botDataProducer.on("transportclose", () => {
        this._botDataProducer = null;
      });

      this._botDataProducer.on("open", () => {
        logger.debug('bot DataProducer "open" event');
      });

      this._botDataProducer.on("close", () => {
        logger.warn('bot DataProducer "close" event');

        this._botDataProducer = null;

        requestActions.notify({
          type: "danger",
          text: "Bot DataProducer closed",
        });
      });

      this._botDataProducer.on("error", (error) => {
        logger.error(
          `bot DataProducer "error" event:${JSON.stringify(error.message)}`
        );

        requestActions.notify({
          type: "danger",
          text: `Bot DataProducer error: ${error}`,
        });
      });

      this._botDataProducer.on("bufferedamountlow", () => {
        logger.debug('bot DataProducer "bufferedamountlow" event');
      });
    } catch (error) {
      logger.error(
        `enableBotDataProducer() failed:${JSON.stringify(error.message)}`
      );

      requestActions.notify({
        type: "danger",
        text: `Error enabling bot DataProducer: ${error}`,
      });

      throw error;
    }
  }

  async sendChatMessage(text) {
    logger.debug(`sendChatMessage() [text:"${text}]`, text);

    if (!this._chatDataProducer) {
      requestActions.notify({
        type: "danger",
        text: "No chat DataProducer",
      });

      return;
    }

    try {
      this._chatDataProducer.send(text);
    } catch (error) {
      logger.error(
        `chat DataProducer.send() failed:${JSON.stringify(error.message)}`
      );

      requestActions.notify({
        type: "danger",
        text: `chat DataProducer.send() failed: ${error}`,
      });
    }
  }

  async sendBotMessage(text) {
    logger.debug(`sendBotMessage() [text:"${text}]`, text);

    if (!this._botDataProducer) {
      requestActions.notify({
        type: "danger",
        text: "No bot DataProducer",
      });

      return;
    }

    try {
      this._botDataProducer.send(text);
    } catch (error) {
      logger.error(
        `bot DataProducer.send() failed:${JSON.stringify(error.message)}`
      );

      requestActions.notify({
        type: "danger",
        text: `bot DataProducer.send() failed: ${error}`,
      });
    }
  }

  async changeDisplayName(displayName) {
    logger.debug(`changeDisplayName() [displayName:"${displayName}"]`);

    cookiesManager.setUser({ displayName });

    try {
      await this._protoo.request("changeDisplayName", { displayName });

      this._displayName = displayName;

      store.dispatch(stateActions.setDisplayName(displayName));

      requestActions.notify({
        text: i18n.global.t("notifications.peer.displayName"),
      });
    } catch (error) {
      logger.error(
        `changeDisplayName() failed:${JSON.stringify(error.message)}`
      );

      requestActions.notify({
        type: "danger",
        text: i18n.global.t("notifications.peer.error.displayName", error),
      });

      store.dispatch(stateActions.setDisplayName(""));
    }
  }

  async getSendTransportRemoteStats() {
    logger.debug("getSendTransportRemoteStats()");

    if (!this._sendTransport) return;

    return this._protoo.request("getTransportStats", {
      transportId: this._sendTransport.id,
    });
  }

  async getRecvTransportRemoteStats() {
    logger.debug("getRecvTransportRemoteStats()");

    if (!this._recvTransport) return;

    return this._protoo.request("getTransportStats", {
      transportId: this._recvTransport.id,
    });
  }

  async getAudioRemoteStats() {
    logger.debug("getAudioRemoteStats()");

    if (!this._micProducer) return;

    return this._protoo.request("getProducerStats", {
      producerId: this._micProducer.id,
    });
  }

  async getVideoRemoteStats() {
    logger.debug("getVideoRemoteStats()");

    const producer = this._webcamProducer || this._shareProducer;

    if (!producer) return;

    return this._protoo.request("getProducerStats", {
      producerId: producer.id,
    });
  }

  async getConsumerRemoteStats(consumerId) {
    logger.debug("getConsumerRemoteStats()");

    const consumer = this._consumers.get(consumerId);

    if (!consumer) return;

    return this._protoo.request("getConsumerStats", { consumerId });
  }

  async getChatDataProducerRemoteStats() {
    logger.debug("getChatDataProducerRemoteStats()");

    const dataProducer = this._chatDataProducer;

    if (!dataProducer) return;

    return this._protoo.request("getDataProducerStats", {
      dataProducerId: dataProducer.id,
    });
  }

  async getBotDataProducerRemoteStats() {
    logger.debug("getBotDataProducerRemoteStats()");

    const dataProducer = this._botDataProducer;

    if (!dataProducer) return;

    return this._protoo.request("getDataProducerStats", {
      dataProducerId: dataProducer.id,
    });
  }

  async getDataConsumerRemoteStats(dataConsumerId) {
    logger.debug("getDataConsumerRemoteStats()");

    const dataConsumer = this._dataConsumers.get(dataConsumerId);

    if (!dataConsumer) return;

    return this._protoo.request("getDataConsumerStats", { dataConsumerId });
  }

  async getSendTransportLocalStats() {
    logger.debug("getSendTransportLocalStats()");

    if (!this._sendTransport) return;

    return this._sendTransport.getStats();
  }

  async getRecvTransportLocalStats() {
    logger.debug("getRecvTransportLocalStats()");

    if (!this._recvTransport) return;

    return this._recvTransport.getStats();
  }

  async getAudioLocalStats() {
    logger.debug("getAudioLocalStats()");

    if (!this._micProducer) return;

    return this._micProducer.getStats();
  }

  async getVideoLocalStats() {
    logger.debug("getVideoLocalStats()");

    const producer = this._webcamProducer || this._shareProducer;

    if (!producer) return;

    return producer.getStats();
  }

  async getConsumerLocalStats(consumerId) {
    const consumer = this._consumers.get(consumerId);

    if (!consumer) return;

    return consumer.getStats();
  }

  async applyNetworkThrottle({ uplink, downlink, rtt, secret }) {
    logger.debug(
      `applyNetworkThrottle() [uplink:${uplink}, downlink:${downlink}, rtt:${rtt}]`
    );

    try {
      await this._protoo.request("applyNetworkThrottle", {
        uplink,
        downlink,
        rtt,
        secret,
      });
    } catch (error) {
      logger.error(
        `applyNetworkThrottle() failed:${JSON.stringify(error.message)}`
      );

      requestActions.notify({
        type: "danger",
        text: `Error applying network throttle: ${error}`,
      });
    }
  }

  async resetNetworkThrottle({ silent = false, secret }) {
    logger.debug("resetNetworkThrottle()");

    try {
      await this._protoo.request("resetNetworkThrottle", { secret });
    } catch (error) {
      if (!silent) {
        logger.error(
          `resetNetworkThrottle() failed:${JSON.stringify(error.message)}`
        );

        requestActions.notify({
          type: "danger",
          text: `Error resetting network throttle: ${error}`,
        });
      }
    }
  }

  async _joinRoom() {
    logger.debug("_joinRoom()");

    try {
      this._mediasoupDevice = new mediasoupClient.Device({
        handlerName: this._handlerName as BuiltinHandlerName,
      });

      const routerRtpCapabilities = await this._protoo.request(
        "getRouterRtpCapabilities"
      );
      await this._mediasoupDevice.load({ routerRtpCapabilities });
      let microphonePermissionDenided = false;
      // Super hack!
      {
        try {
          await navigator.mediaDevices
            .getUserMedia({
              audio: true,
              video: true,
            })
            .then((stream) => {
              setTimeout(() => {
                if (stream) {
                  const audioTrack = stream.getAudioTracks()[0];
                  const videoTrack = stream.getVideoTracks()[0];

                  audioTrack.enabled = false;
                  videoTrack.enabled = false;
                  audioTrack.stop();
                  videoTrack.stop();
                }
              }, 1000)
            });
        } catch (e) {
          requestActions.notify({
            type: "danger",
            text: i18n.global.t("notifications.microphone.error.on", e),
          });
          microphonePermissionDenided = true;
        }
      }

      if (this._produce) {
        const transportInfo = await this._protoo.request(
          "createWebRtcTransport",
          {
            forceTcp: this._forceTcp,
            producing: true,
            consuming: false,
            sctpCapabilities: this._useDataChannel
              ? this._mediasoupDevice.sctpCapabilities
              : undefined,
          }
        );

        const {
          id,
          iceParameters,
          iceCandidates,
          dtlsParameters,
          sctpParameters,
        } = transportInfo;

        this._sendTransport = this._mediasoupDevice.createSendTransport({
          id,
          iceParameters,
          iceCandidates,
          dtlsParameters,
          sctpParameters,
          iceServers: [],
          proprietaryConstraints: PC_PROPRIETARY_CONSTRAINTS,
          additionalSettings: {
            encodedInsertableStreams: this._e2eKey && e2e.isSupported(),
          },
        });

        this._sendTransport.on(
          "connect",
          (
            { dtlsParameters },
            callback,
            errback // eslint-disable-line no-shadow
          ) => {
            this._protoo
              .request("connectWebRtcTransport", {
                transportId: this._sendTransport.id,
                dtlsParameters,
              })
              .then(callback)
              .catch(errback);
          }
        );

        this._sendTransport.on(
          "produce",
          async ({ kind, rtpParameters, appData }, callback, errback) => {
            try {
              // eslint-disable-next-line no-shadow
              const { id } = await this._protoo.request("produce", {
                transportId: this._sendTransport.id,
                kind,
                rtpParameters,
                appData,
              });

              callback({ id });
            } catch (error) {
              errback(error);
            }
          }
        );

        this._sendTransport.on(
          "producedata",
          async (
            { sctpStreamParameters, label, protocol, appData },
            callback,
            errback
          ) => {
            logger.debug(
              `"producedata" event: [sctpStreamParameters:${JSON.stringify(
                sctpStreamParameters
              )}, appData:${JSON.stringify(appData)}]`
            );

            try {
              // eslint-disable-next-line no-shadow
              const { id } = await this._protoo.request("produceData", {
                transportId: this._sendTransport.id,
                sctpStreamParameters,
                label,
                protocol,
                appData,
              });

              callback({ id });
            } catch (error) {
              errback(error);
            }
          }
        );
      }

      if (this._consume) {
        const transportInfo = await this._protoo.request(
          "createWebRtcTransport",
          {
            forceTcp: this._forceTcp,
            producing: false,
            consuming: true,
            sctpCapabilities: this._useDataChannel
              ? this._mediasoupDevice.sctpCapabilities
              : undefined,
          }
        );

        const {
          id,
          iceParameters,
          iceCandidates,
          dtlsParameters,
          sctpParameters,
        } = transportInfo;

        this._recvTransport = this._mediasoupDevice.createRecvTransport({
          id,
          iceParameters,
          iceCandidates,
          dtlsParameters,
          sctpParameters,
          iceServers: [],
          additionalSettings: {
            encodedInsertableStreams: this._e2eKey && e2e.isSupported(),
          },
        });

        this._recvTransport.on(
          "connect",
          (
            { dtlsParameters },
            callback,
            errback // eslint-disable-line no-shadow
          ) => {
            this._protoo
              .request("connectWebRtcTransport", {
                transportId: this._recvTransport.id,
                dtlsParameters,
              })
              .then(callback)
              .catch(errback);
          }
        );
      }

      const { peers } = await this._protoo.request("join", {
        displayName: this._displayName,
        device: this._device,
        rtpCapabilities: this._consume
          ? this._mediasoupDevice.rtpCapabilities
          : undefined,
        sctpCapabilities:
          this._useDataChannel && this._consume
            ? this._mediasoupDevice.sctpCapabilities
            : undefined,
      });

      const connected = stateActions.setRoomState("connected");
      connected.type.filter((t) => store.dispatch(t, connected));

      if (!store.state.room.isRefresh) {
        requestActions.notify({
          text: i18n.global.t("notifications.peer.join"),
          timeout: 3000,
        });
      }

      for (const peer of peers) {
        store.dispatch(
          stateActions.addPeer({ ...peer, consumers: [], dataConsumers: [] })
        );
      }

      if (this._produce) {
        store.dispatch(
          stateActions.setMediaCapabilities({
            canSendMic: this._mediasoupDevice.canProduce("audio"),
            canSendWebcam: this._mediasoupDevice.canProduce("video"),
          })
        );
        if (!microphonePermissionDenided) {
          await this.enableMic();
        }

        const devicesCookie = cookiesManager.getDevices();

        if (
          !devicesCookie ||
          devicesCookie.webcamEnabled ||
          this._externalVideo
        )
          await this.enableWebcam();

        this.sendCallbackEvent('join', this._roomId);
        this._sendTransport.on("connectionstatechange", (connectionState) => {
          if (connectionState === "connected") {
            // this.enableChatDataProducer();
            // this.enableBotDataProducer();
          } /*else if (
            connectionState === "disconnected" ||
            connectionState?.connectionState === "disconnected"
          ) {
            store.dispatch("componentKey");
          }*/
        });
        this.retail_mod = await this.getAppMod();
        this.show_blocks = true;
      }
    } catch (error) {
      logger.error(`_joinRoom() failed:${JSON.stringify(error.message)}`);

      requestActions.notify({
        type: "danger",
        text: i18n.global.t("notifications.peer.error.join", error),
      });
      window.location.reload();
    }
  }

  async _updateWebcams() {
    logger.debug("_updateWebcams()");

    // Reset the list.
    this._webcams = new Map();

    logger.debug("_updateWebcams() calling enumerateDevices()");

    const devices = await navigator.mediaDevices.enumerateDevices();

    for (const device of devices) {
      if (device.kind !== "videoinput") continue;

      this._webcams.set(device.deviceId, device);
    }

    const array = Array.from(this._webcams.values());
    const len = array.length;
    const currentWebcamId = this._webcam.device
      ? this._webcam.device.deviceId
      : undefined;

    logger.debug(`_updateWebcams() [webcams:${JSON.stringify(array)}]`);

    if (len === 0) this._webcam.device = null;
    else if (!this._webcams.has(currentWebcamId))
      this._webcam.device = array[0];

    store.dispatch(stateActions.setCanChangeWebcam(this._webcams.size > 1));
  }

  _getWebcamType(device) {
    if (/(back|rear)/i.test(device.label)) {
      logger.debug("_getWebcamType() it seems to be a back camera");

      return "back";
    } else {
      logger.debug("_getWebcamType() it seems to be a front camera");

      return "front";
    }
  }

  async _pauseConsumer(consumer) {
    if (consumer.paused) return;

    try {
      await this._protoo.request("pauseConsumer", { consumerId: consumer.id });

      consumer.pause();

      store.dispatch(stateActions.setConsumerPaused(consumer.id, "local"));
    } catch (error) {
      logger.error(`_pauseConsumer() failed:${JSON.stringify(error.message)}`);

      requestActions.notify({
        type: "danger",
        text: i18n.global.t("notifications.peer.error.pausing", error),
      });
    }
  }

  async _resumeConsumer(consumer) {
    if (!consumer.paused) return;

    try {
      await this._protoo.request("resumeConsumer", { consumerId: consumer.id });

      consumer.resume();

      store.dispatch(stateActions.setConsumerResumed(consumer.id, "local"));
    } catch (error) {
      logger.error(`_resumeConsumer() failed:${JSON.stringify(error.message)}`);

      requestActions.notify({
        type: "danger",
        text: i18n.global.t("notifications.peer.error.resuming", error),
      });
    }
  }

  async _getExternalVideoStream() {
    if (this._externalVideoStream) return this._externalVideoStream;

    if (this._externalVideo.readyState < 3) {
      await new Promise((resolve) =>
        this._externalVideo.addEventListener("canplay", resolve)
      );
    }

    if (this._externalVideo.captureStream)
      this._externalVideoStream = this._externalVideo.captureStream();
    else if (this._externalVideo.mozCaptureStream)
      this._externalVideoStream = this._externalVideo.mozCaptureStream();
    else throw new Error("video.captureStream() not supported");

    return this._externalVideoStream;
  }
}
