// eslint-disable-next-line @typescript-eslint/no-var-requires
// @ts-nocheck
import axios from "axios";

export default class LoggerAxios {
  constructor(module_name = "") {
    this.module_name = module_name;
    this.labels = { module_name };
    if (
        process.env.VUE_APP_LOKI_USER
        && process.env.VUE_APP_LOKI_PASS
        && process.env.VUE_APP_LOKI_URL
    ) {
      if (process.env.VUE_APP_LOKI_PROJECT) {
        this.labels.name = process.env.VUE_APP_LOKI_PROJECT;
        this.config = this.prepare_config();
        this.labels.project = "livecart";
      }
    }
  }

  child(module_name = "") {
    return new LoggerAxios(module_name);
  }

  info(msg, labels = {}) {
    labels = {
      ...this.labels,
      ...labels,
    };
    const data = this._prepareDataRequest(msg, labels, "info");

    return this._send_axios(data, "info");
  }

  log(msg) {
    return this.info(msg);
  }

  async error(msg, labels = {}) {
    labels = {
      ...this.labels,
      ...labels,
    };
    const data = this._prepareDataRequest(msg, labels, "error");

    return this._send_axios(data, "error");
  }

  warn(msg, labels = {}) {
    labels = {
      ...this.labels,
      ...labels,
    };
    const data = this._prepareDataRequest(msg, labels, "warn");

    return this._send_axios(data, "warn");
  }

  debug(msg, labels = {}) {
    labels = {
      ...this.labels,
      ...labels,
    };
    const data = this._prepareDataRequest(msg, labels, "debug");
    if (process.env.VUE_APP_LOG_LVL !== "prod") {
      return this._send_axios(data, "debug");
    } else {
      return true;
    }
  }

  prepare_config() {
    return {
      headers: { "Content-Type": "application/json", "Access-Control-Allow-Origin": "*" },
      auth: {
        username: process.env.VUE_APP_LOKI_USER,
        password: process.env.VUE_APP_LOKI_PASS,
      },
    };
  }

  /**
   * Method for sending logs using axios to loki api
   */
  async _send_axios(data, level = "debug") {
    const log = {
      msg: data,
      level: level
    }
   // await axios.post(process.env.VUE_APP_SERVER_LINK + "/log", log);
      if (
          process.env.VUE_APP_LOKI_USER
          && process.env.VUE_APP_LOKI_USER !== ""
          && process.env.VUE_APP_LOKI_PASS
          && process.env.VUE_APP_LOKI_PASS !== ""
          && process.env.VUE_APP_LOKI_URL
          && process.env.VUE_APP_LOKI_URL !== ""
      ) {
        await axios.post(process.env.VUE_APP_LOKI_URL, data, this.config);
      }
  }

  /**
   * Method for preparing request data
   * @returns string
   */
  _prepareDataRequest(msg, labels = {}, level = "info") {
    let logfmtMsg = `level=${level} msg=${JSON.stringify(msg)}`;

    if (this.module_name) {
      logfmtMsg += ` module=${this.module_name}`;
    }

    return JSON.stringify({
      streams: [
        {
          stream: labels,
          values: [[Date.now() + "000000", logfmtMsg]],
        },
      ],
    });
  }
}
