import { VueCookieNext } from "vue-cookie-next";

const USER_COOKIE = "mediasoup-demo.user";
const DEVICES_COOKIE = "mediasoup-demo.devices";

export function getUser() {
  if (VueCookieNext.isCookieAvailable(USER_COOKIE)) {
    return JSON.parse(VueCookieNext.getCookie(USER_COOKIE));
  }

  return null;
}

export function setUser({ displayName }) {
  VueCookieNext.setCookie(USER_COOKIE, JSON.stringify({ displayName }));
}

export function getDevices() {
  if (VueCookieNext.isCookieAvailable(USER_COOKIE)) {
    return JSON.parse(VueCookieNext.getCookie(DEVICES_COOKIE));
  }

  return null;
}

export function setDevices({ webcamEnabled }) {
  VueCookieNext.setCookie(DEVICES_COOKIE, JSON.stringify({ webcamEnabled }));
}
